<template>
    <div>
        <ek-dialog
            :title="isEdit ? 'تعديل المنطقة' : 'إضافة منطقة'"
            ref="dialog"
            :isEdit="isEdit"
            @delete="deleteArea(areaDto.id)"
            @ok="submit"
        >
            <template #body>
                <b-row no-gutters>
                    <b-col>
                        <ValidationObserver ref="form">
                            <ek-input-text
                                name="اسم المنطقة"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'اسم المنطقة مطلوب'
                                    }
                                ]"
                                v-model="areaDto.name"
                                label="اسم المنطقة"
                                placeholder="ادخل اسم المنطقة"
                            ></ek-input-text>
                        </ValidationObserver>
                    </b-col>
                </b-row>
            </template>
        </ek-dialog>

        <b-container>
            <b-row>
                <b-col>
                    <ek-table
                        title="المناطق"
                        isPlus
                        @delete-selected="deleteAreas"
                        :columns="cols"
                        :items="areas"
                        @plus="openAddDialog"
                        @details="openEditDialog"
                    >
                    </ek-table>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import EkInputText from "@/EK-core/components/EK-forms/EK-input-text";
import EkDialog from "@/EK-core/components/EK-dialog";
import EkTable from "@/EK-core/components/EK-table";
import { mapActions, mapState } from "vuex";

export default {
    components: { EkTable, EkDialog, EkInputText, ValidationObserver },
    data() {
        return {
            cols: [
                { label: "اسم المنطقة", field: "name" },
                { label: "إجرائات", field: "details" }
            ]
        };
    },
    computed: {
        ...mapState({
            areas: state => state.store.areas,
            areaDto: state => state.store.areaDto
        }),
        isEdit() {
            return !!this.areaDto.id;
        }
    },
    methods: {
        ...mapActions([
            "getAreas",
            "addArea",
            "updateArea",
            "deleteAreas",
            "deleteArea"
        ]),
        openAddDialog() {
            this.$refs.form.reset();
            this.$store.commit("Set_Area_Dto");
            this.$refs.dialog.open();
        },
        openEditDialog({ row }) {
            this.$store.commit("Set_Area_Dto", { name: row.name, id: row.id });
            this.$refs.dialog.open();
        },
        submit() {
            this.$refs.form.validate().then(suc => {
                if (suc) {
                    if (this.isEdit) {
                        this.updateArea(this.areaDto);
                    } else {
                        this.addArea(this.areaDto);
                    }
                    this.$refs.dialog.close();
                    this.$refs.form.reset();
                }
            });
        }
    },
    created() {
        this.getAreas();
    }
};
</script>

<style></style>
